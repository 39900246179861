import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import BusinessIcon from "@material-ui/icons/Business"
import ContactPhoneIcon from "@material-ui/icons/ContactPhone"
import EmailIcon from "@material-ui/icons/Email"
import FacebookIcon from "@material-ui/icons/Facebook"
import HomeWork from "@material-ui/icons/HomeWork"
import InstagramIcon from "@material-ui/icons/Instagram"
import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles(() =>
  createStyles({
    font: {
      marginBottom: "48px",
      maxWidth: "743px",
      margin: "auto",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "463px",
      margin: "auto",
    },
    textField: {
      marginBottom: "16px",
    },
  })
)

interface Props {
  title: string
  heading: string
  subheading: string
}

export const ContactUsPageTemplate = ({
  title,
  heading,
  subheading,
}: Props) => {
  const classes = useStyles()

  return (
    <Container>
      <SEO
        title="Contact Us"
        description="Get in touch today to find out more information about the wide range of ITEC and CIBTAC professional qualifications in the areas of Beauty Therapy, Massage and Complementary Therapies to we offer."
      />
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "70px",
            marginBottom: "20px",
          }}
        >
          <h1>Contact Us</h1>
          <h2>{heading}</h2>
          <p className={classes.font}>We are now enrolling for 2021 courses</p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h3>The Institute of Beauty</h3>
          <p style={{ display: "flex" }}>
            <BusinessIcon style={{ marginRight: "8px" }} />
            <span>
              St. Catherine’s Hall,
              <br />
              Catherine Street,
              <br />
              Waterford City,
              <br />
              Ireland
            </span>
          </p>
          <p style={{ display: "flex", wordBreak: "break-word" }}>
            <ContactPhoneIcon style={{ marginRight: "8px" }} />
            <a href="tel:+35351877753">(051) 877 753</a> /{" "}
            <a href="tel:+353876860382">(087) 686 0382</a>
          </p>
          <p style={{ display: "flex" }}>
            <EmailIcon
              style={{
                marginRight: "8px",
                wordBreak: "break-word",
                alignItems: "center",
              }}
            />
            <a href="mailto: theinstituteofbeauty@gmail.com">
              theinstituteofbeauty@gmail.com
            </a>
          </p>
          <p style={{ display: "flex" }}>
            <HomeWork
              style={{
                marginRight: "8px",
                wordBreak: "break-word",
                alignItems: "center",
              }}
            />
            <a
              href="https://theclinicofbeauty.ie/"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noopener"
            >
              The Clinic Beauty Salon
            </a>
          </p>
          <p
            style={{
              display: "flex",
              wordBreak: "break-word",
              alignItems: "center",
            }}
          >
            <FacebookIcon style={{ marginRight: "8px" }} />
            <a
              href="https://www.facebook.com/TheInstituteBeautyWaterford" // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noopener"
            >
              https://www.facebook.com/TheInstituteBeautyWaterford/
            </a>
          </p>
          <p
            style={{
              display: "flex",
              wordBreak: "break-word",
              alignItems: "center",
            }}
          >
            <InstagramIcon style={{ marginRight: "8px" }} />
            <a
              href="https://www.instagram.com/theinstituteofbeauty" // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noopener"
            >
              https://www.instagram.com/theinstituteofbeauty/
            </a>
          </p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <iframe
            title="Google Maps"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: "0" }}
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJiakKG5nGQkgRA3oXaf9EQ-U&key=AIzaSyCKsZHgJfOYn0gVW7eoXkUG9_xQdblXII8"
            allowFullScreen
          ></iframe>
        </Grid>
      </Grid>
    </Container>
  )
}

interface ContactUsPageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        [key: string]: any
      }
    }
  }
}

const ContactUsPage = ({ data }: ContactUsPageProps) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ContactUsPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  )
}

export default ContactUsPage

export const pageQuery = graphql`
  query ContactUsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-us-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`
